import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import Layout from '../../components/layout/layout';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = (props, location) => <Layout title="❓ Note not found..." description="Something went wrong." type="📝" location={location} {...props} />;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`The note you were looking for has not been published yet.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      